type CtaType = {
  title: string;
  externalUrl: string;
};

export type ItemType = {
  id: string;
  title: string;
  shortDescription?: { [key: string]: string };
  text?: string;
  cta?: CtaType;
  slug?: string;
  image?: any;
};

type Props = {
  shortDescription?: { [key: string]: string };
  text?: string;
};

const extractShortDescription = (data: any) => {
  if (!data) {
    return '';
  }

  const { shortDescription, text = '' }: Props = data;

  return shortDescription && shortDescription.text ? shortDescription.text : '' || text;
};

export default extractShortDescription;
