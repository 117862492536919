import extractCta from './Cta';
import { ContentfulEventConnection, ContentfulEventEdge } from '../generated/gatsby.types';

const transformEvents = (edges: Array<ContentfulEventEdge>) => {
  return edges
    .map((edge: ContentfulEventEdge): any => {
      const eventItem = edge.node as any;
      if (eventItem) {
        return {
          id: eventItem.id,
          startDate: eventItem.startDate,
          title: eventItem.title,
          shortDescription:
            eventItem.shortDescription && eventItem.shortDescription.text ? eventItem.shortDescription.text : '',
          date: eventItem.displayDate,
          location: eventItem.location,
          image: eventItem.image?.asset?.fixed?.src,
          link: 'www.google.com',
        };
      }

      return null;
    })
    .filter(Boolean)
    .filter((item: any) => {
      return item.startDate > new Date().toISOString();
    })
    .slice(0, 4);
};

const transformUpcomingEventsSection = (item: any, events: ContentfulEventConnection) => {
  return {
    __typename: 'UpcomingEvents',
    key: item.id,
    title: item.title,
    shortDescription: item.shortDescription && item.shortDescription.text ? item.shortDescription.text : '',
    cta: extractCta(item),
    upcomingEvents: transformEvents(events.edges),
  };
};

export default transformUpcomingEventsSection;
