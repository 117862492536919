const extractCta = (item: any) => {
  if (item.cta && item.cta.internalEntry && item.cta.internalEntry.slug) {
    const to = item.cta.internalEntry.slug === 'homepage' ? '/' : item.cta.internalEntry.slug;

    return {
      label: item.cta.title,
      to,
    };
  }

  if (item.cta) {
    return {
      label: item.cta.title,
      to: item.cta.externalUrl,
    };
  }

  return null;
};

export default extractCta;
