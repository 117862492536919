import React from 'react';
import { graphql } from 'gatsby';
import { EventPage, handleNetlifyFormSubmission } from 'arvesta-ui-library';
//
import { ContentfulEvent, ContentfulSettings, ContentfulEventConnection } from '../generated/gatsby.types';
import Layout from '../components/layout';
import extractShortDescription from '../transforms/ShortDescription';
import transformUpcomingEventsSection from '../transforms/UpcomingEvents';
import extractFileInforamtion from '../transforms/File';

type EventQueryType = {
  contentfulEvent: ContentfulEvent;
  contentfulCookieBanner: any;
  site: any;
  allContentfulEvent: ContentfulEventConnection;
  contentfulSettings: ContentfulSettings;
  contentfulUpcomingEvents: any;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type EventProps = {
  data: EventQueryType;
  pageContext: PageContextType;
};

const Event = ({ data, pageContext }: EventProps) => {
  const {
    title,
    image,
    location,
    displayDate,
    shortDescription,
    contentsSection,
    showUpcomingEvents,
    document,
    updatedAt,
  } = data.contentfulEvent;

  return (
    <Layout
      pageTitle={title as string}
      pageDescription={extractShortDescription(shortDescription as any)}
      pageImage={image || undefined}
      pageLocale={pageContext.node_locale}
      pageType="event"
      siteUrl={data.site.siteMetadata.siteUrl}
      pageSlug={data.contentfulEvent.slug ? data.contentfulEvent.slug : ''}
      contentfulSettings={data.contentfulSettings}
      pageSettings={data.contentfulEvent.metaTags}
      swiftTypeDate={data.contentfulEvent.displayDate ? data.contentfulEvent?.displayDate : undefined}
      swiftTypeLocation={data.contentfulEvent.location ? data.contentfulEvent?.location : undefined}
      cookieBannerTitle={data.contentfulCookieBanner.title}
      cookieBannerDescription={data.contentfulCookieBanner.description}
      updatedAt={updatedAt}
      ogPageType="article"
      showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
      subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
      flexmailId={data.contentfulSettings.flexmailId}
    >
      <EventPage
        title={title as string}
        shortDescription={extractShortDescription(shortDescription as any)}
        eventDate={displayDate as string}
        eventLocation={location as string}
        contentsSection={{ data: contentsSection ? contentsSection.json : null }}
        image={image}
        handleFormSubmit={handleNetlifyFormSubmission as any}
        showContactsFormSubscribeOptions
        eventModuleProps={
          showUpcomingEvents
            ? transformUpcomingEventsSection(data.contentfulUpcomingEvents, data.allContentfulEvent)
            : null
        }
        documentDownload={extractFileInforamtion(document)}
      />
    </Layout>
  );
};

export default Event;

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulCookieBanner(node_locale: { eq: $node_locale }) {
      title
      description {
        json
      }
    }
    contentfulEvent(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      title
      slug
      metaTags {
        id
        description
        nofollow
        title
        keywords
        canonical
        noindex
      }
      document {
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      image {
        title
        alt: altText
        asset {
          fluid(quality: 85) {
            ...QueryImageFluid
          }
        }
        socialImage: asset {
          fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
            ...QueryImageFixed
          }
        }
      }
      location
      displayDate
      shortDescription {
        text: shortDescription
      }
      contentsSection {
        json
      }
      showUpcomingEvents
    }

    contentfulSettings(node_locale: { eq: $node_locale }) {
      ...QuerySiteSettings
    }

    contentfulUpcomingEvents {
      ...QueryUpcomingEvents
    }

    allContentfulEvent(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          updatedAt(formatString: "YYYYMMDDHHmmss")
          id
          displayDate
          startDate
          slug
          shortDescription {
            text: shortDescription
          }
          location
          title
          image {
            title
            alt: altText
            asset {
              fixed(width: 510, height: 340, resizingBehavior: FILL, quality: 85) {
                ...QueryImageFixed
              }
            }
          }
        }
      }
    }
  }
`;
